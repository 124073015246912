import React from "react";
import css from "./SignUp.module.css";

export default (props) =>
  <form className={css.form}>
    <label className={css.label} htmlFor="email">{props.label}</label>
    <input
      className={css.input}
      type="email"
      name="email"
      id="email"
      required
    />
    <input type="button" className={css.submit} value={props.submit} />
  </form>
