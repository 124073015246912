import React from 'react'
import Helmet from 'react-helmet'
import Header from './Header.jsx';
import { graphql, StaticQuery } from 'gatsby'
import 'normalize.css';
import '../global.css'

const pageQuery = graphql`
  query {
    header: allDataJson(filter: {key: {eq: "app-header"}}) {
      edges {
        node {
          logo {
            src
            alt
            url
          }
          navigations {
            cta
            url
          }
          signup {
            label
            submit
          }
          button {
            cta
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default (props) =>
  <StaticQuery
    query={pageQuery}
    render={data => (
      <div className="c-container">
        <Helmet
          key="app-head"
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
        >
          <html lang="en" />
          <title>{props.title}</title>
        </Helmet>
        <Header {...data.header.edges[0].node} />
        {props.children}
      </div>
    )}
  />
