import React from "react";
import { Link } from "gatsby"
import css from "./Header.module.css";
import SignUp from './SignUp';

export default (props) =>
  <div className={css.header}>
    <Link to={props.logo.url} className={css.logo}>
      <img
        src={props.logo.src}
        alt={props.logo.alt}
      />
    </Link>

    <div className={css.navigation}>
      <div className={css.navigationLinks}>
        {
          props.navigations.map(nav => (
            <Link
              to={nav.url}
              activeStyle={{
                color: "black",
              }}
              key={nav.url}>
              {nav.cta}
            </Link>
          ))
        }
      </div>

      <div className={css.formContainer}>
        <SignUp {...props.signup} />
      </div>
    </div>

    <a href={props.button.url} className={css.buy}>{props.button.cta}</a>
  </div>
