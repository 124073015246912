import React from 'react';
import Layout from '../components/Layout.jsx';

export default ({ data }) => {
  return (
    <Layout title="Brands">
      <h1>Brands</h1>
    </Layout>
  )
}
